// Ensure polyfills are only added if they don't already exist
(function () {
  if (!Set.prototype.intersection) {
    Set.prototype.intersection = function (other) {
      if (!(other instanceof Set)) {
        throw new TypeError('Argument must be a Set');
      }
      return new Set([...this].filter((x) => other.has(x)));
    };
  }

  if (!Set.prototype.union) {
    Set.prototype.union = function (other) {
      if (!(other instanceof Set)) {
        throw new TypeError('Argument must be a Set');
      }
      return new Set([...this, ...other]);
    };
  }

  if (!Set.prototype.difference) {
    Set.prototype.difference = function (other) {
      if (!(other instanceof Set)) {
        throw new TypeError('Argument must be a Set');
      }
      return new Set([...this].filter((x) => !other.has(x)));
    };
  }

  if (!Set.prototype.symmetricDifference) {
    Set.prototype.symmetricDifference = function (other) {
      if (!(other instanceof Set)) {
        throw new TypeError('Argument must be a Set');
      }
      return new Set([...this].filter((x) => !other.has(x)).concat([...other].filter((x) => !this.has(x))));
    };
  }

  if (!Set.prototype.isSubsetOf) {
    Set.prototype.isSubsetOf = function (other) {
      if (!(other instanceof Set)) {
        throw new TypeError('Argument must be a Set');
      }
      return [...this].every((x) => other.has(x));
    };
  }

  if (!Set.prototype.isSupersetOf) {
    Set.prototype.isSupersetOf = function (other) {
      if (!(other instanceof Set)) {
        throw new TypeError('Argument must be a Set');
      }
      return [...other].every((x) => this.has(x));
    };
  }

  if (!Set.prototype.isDisjointFrom) {
    Set.prototype.isDisjointFrom = function (other) {
      if (!(other instanceof Set)) {
        throw new TypeError('Argument must be a Set');
      }
      return [...this].every((x) => !other.has(x));
    };
  }
})();
